var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msg" },
    [
      _c("div", { staticClass: "msg-text" }, [
        _c("div", [_vm._v(_vm._s(_vm.msg))]),
        _vm.docs.length != 0
          ? _c("section", [
              _c(
                "div",
                { staticClass: "tips-click", on: { click: _vm.reference } },
                [
                  _c("div", [_vm._v("参考文件")]),
                  _vm.show == false
                    ? _c("div", { staticClass: "icons iconfont icon-sort-up" })
                    : _vm._e(),
                  _vm.show == true
                    ? _c("div", {
                        staticClass: "icons iconfont icon-sort-down",
                      })
                    : _vm._e(),
                ]
              ),
              _vm.show
                ? _c(
                    "section",
                    { staticClass: "animate__animated animate__fadeIn" },
                    [
                      _vm.defaultHtml != ""
                        ? _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.defaultHtml) },
                          })
                        : _c(
                            "div",
                            _vm._l(_vm.docsParse, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "docs-parse-item" },
                                [
                                  _c("div", {
                                    staticClass: "iconfont icon-link",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "docs-parse-item-title",
                                      on: {
                                        click: function ($event) {
                                          return _vm.testOpen(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item["fileName"]))]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c(
        "van-popup",
        {
          style: { height: "70%" },
          attrs: { position: "bottom", closeable: "" },
          model: {
            value: _vm.showPdf,
            callback: function ($$v) {
              _vm.showPdf = $$v
            },
            expression: "showPdf",
          },
        },
        [
          _c("vue-pdf-embed", {
            ref: "vuepdf",
            attrs: { source: _vm.showPdfFile },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }