<template>
  <!-- <div class="msg animate__animated animate__fadeInLeft"> -->
  <div class="msg">
    <div class="msg-text">
      <div>{{ msg }}</div>
      <section v-if="docs.length != 0">
        <div @click="reference" class="tips-click">
          <div>参考文件</div>
          <div v-if="show == false" class="icons iconfont icon-sort-up"></div>
          <div v-if="show == true" class="icons iconfont icon-sort-down"></div>
        </div>
        <section v-if="show" class="animate__animated animate__fadeIn">
          <div v-if="defaultHtml != ''" v-html="defaultHtml"></div>
          <div v-else>
            <div class="docs-parse-item" v-for="(item, index) in docsParse" :key="index">
              <div class="iconfont icon-link"></div>
              <div class="docs-parse-item-title" @click="testOpen(item)">{{ item['fileName'] }}</div>
            </div>
          </div>
          <!-- <vue-markdown class="tips" v-for="(item, index) in docs" :key="index" :source="item"></vue-markdown> -->
        </section>
        <!-- <div class="tips" v-for="(item, index) in docs" :key="index" v-html="item"></div> -->
      </section>
    </div>
    <van-popup v-model="showPdf" position="bottom" closeable :style="{ height: '70%' }">
      <vue-pdf-embed ref="vuepdf" :source="showPdfFile" />
    </van-popup>
  </div>
</template>
<script>
// import VueMarkdown from 'vue-markdown'
import MarkdownIt from 'markdown-it'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'respond',
  components: {
    VuePdfEmbed,
  },
  props: {
    msg: {
      type: String,
      default: '',
      required: true,
    },
    docs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      defaultHtml: '',
      docsParse: [],
      showPdf: false,
      showPdfFile: '',
      showPdfKey: 0,
    }
  },
  computed: {},
  watch: {
    docs: {
      handler(val) {
        console.log('docs-watch', val)
        this.handlerMarkdown(val)
      },
      immediate: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    reference() {
      this.show = !this.show
    },
    handlerMarkdown(docsRow) {
      const md = new MarkdownIt()
      try {
        docsRow.forEach((docsItem) => {
          const jsonOutput = md.parse(docsItem, {})

          // console.log(jsonOutput)
          let inLineRows = jsonOutput.filter((item) => item.type == 'inline')
          // console.log(inLineRows)
          if (inLineRows.length > 1) {
            let docsItemObj = {
              fileName: '',
              filePath: '',
              source: '',
            }
            inLineRows.forEach((inLineRowsItem) => {
              // console.log(inLineRowsItem)
              if (inLineRowsItem['children'].length == 1) {
                docsItemObj['source'] = inLineRowsItem['children'][0]['content']
              } else {
                inLineRowsItem['children'].forEach((inLineRowsItemChild, inLineRowsItemChildIndex) => {
                  // console.log(inLineRowsItemChild)
                  if (inLineRowsItemChild['type'] == 'link_open') {
                    // console.log('----->', inLineRowsItemChild['attrs'], inLineRowsItemChild['attrs'][0][1])
                    docsItemObj.fileName = inLineRowsItem['children'][inLineRowsItemChildIndex + 1]['content']
                    docsItemObj.filePath = inLineRowsItemChild['attrs'][0][1]
                  }
                })
              }
            })
            // if (inLineRows[0]['children'][1]['type'] == 'link_open') {
            //   docsItemObj.fileName = inLineRows[0]['children'][2]['content']
            //   docsItemObj.filePath = inLineRows[0]['children'][0]['attrs'][1]
            // }
            this.docsParse.push(docsItemObj)
            // console.log(docsItemObj)
          } else {
            this.defaultHtml = docsItem
          }
        })
      } catch (error) {}
    },
    testOpen(item) {
      console.log(item)
      this.showPdfFile = `/api${item.filePath}`
      // this.showPdfFile = `https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf`
      this.showPdfKey = new Date().valueOf()

      this.$nextTick(() => {
        this.showPdf = true
        // console.dir(this.$refs.vuepdf);
      })
      // return
      // console.log(window.location.origin)
      // window.jme.file.openDocument({
      //   url: `${window.location.origin}/api${item.filePath}`,
      //   callback: function () {},
      // })
      // window.jme.browser.openUrl({
      //   url: item.filePath,
      //   callback: function () {},
      // })
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.msg {
  margin: 10px 0;
  // color: #646466;
  color: white;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  //   padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .msg-text {
    display: inline-block;
    background-color: #fff;
    // border-radius: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 10px 10px 5px;
    margin-left: 5px;
    margin-right: 35px;
    line-height: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    // background: linear-gradient(90deg, rgba(43, 111, 254, 60%) 0%, rgba(43, 111, 254, 10%) 100%);
    background-color: #379eca;
    .tips {
      font-size: 10px;
      color: #0000ff !important;
    }
    .docs-parse-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .docs-parse-item-title {
        margin-left: 5px;
        color: #0000ff;
      }
    }
    .tips-click {
      font-size: 12px;
      color: #0000ff;
      line-height: 20px;
      // align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      // .icons{
      //   margin-top: 3px;
      // }
    }
  }
}
</style>
